import React from "react"
import { graphql } from "gatsby"
import PostListPage from "../components/PostListPage"

export const query = graphql`
  query($slug: String!, $skip: Int!, $limit: Int!) {
    posts: allContentfulPost(
      filter: { category: { eq: $slug } }
      sort: { fields: [createdAtOriginal, createdAt], order: [DESC, DESC] }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostCellFragment
        }
      }
    }
    categoryList: allContentfulPost {
      group(field: category) {
        fieldValue
        edges {
          node {
            slug
          }
        }
      }
    }
    tagList: allContentfulPost {
      distinct(field: tags)
    }
  }
`

const Page = (props) => <PostListPage {...props} />

export default Page
